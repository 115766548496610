require('./bootstrap');


$(function() {
    $('#main-nav').on('show.bs.collapse', function() {
        $(this).parents('body').addClass('disable-scroll navbar-active');
        $('.nav-icon').addClass('open');

    });
    $('#main-nav').on('hide.bs.collapse', function() {
        $(this).parents('body').removeClass('disable-scroll navbar-active');
        $('.nav-icon').removeClass('open');
    });

    $(window).resize(function() {
        if ($(window).width() > 1003) {
            $('body').removeClass('disable-scroll navbar-active');
            $('#navbarDefault').removeClass('show');
            $('.nav-icon').addClass('collapsed').removeClass('open');
        }
    });
});
